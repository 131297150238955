import React from "react"
import ArticleLayout from "../components/ArticleLayout"
import styled from "styled-components"

import { Text, Stack, Heading, themeGet } from "@fuegokit/react"

const About = () => {
  return (
    <ArticleLayout title="About Design Systems at Appfire">
      <Heading as="h1">About Design Systems at Appfire</Heading>
      <Text>
        The Design Systems team manages processes and tools that support people
        designing and building digital experiences at Appfire.
      </Text>

      <Stack size={3}>
        <StyledHeading>Focus</StyledHeading>
        <Text>
          Our primary task is to improve the design and development workflow by
          enabling teams at Appfire to design and build software more
          efficiently, consistently, and collaboratively.
        </Text>
      </Stack>
      <Stack size={3}>
        <StyledHeading>Mission</StyledHeading>
        <Text>
          Our promise is to create an effective, governed design system that can
          be adopted across products that is sustained with a dependable,
          predictable set of transparent processes.
        </Text>
      </Stack>
      <Stack size={3}>
        <StyledHeading>Values</StyledHeading>
        <Text>
          We base our philosophy on the principles of transparency,
          collaboration, learning, experimentation, and passion.
        </Text>

        <ValuesList>
          <ValueCard>
            <Value>
              Create simple solutions for a consistent experience across
              products.
            </Value>
            <Text>
              We favor the elegance of simplicity over the allure of complexity.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Everything is a component.</Value>
            <Text>
              We are building for scale, and we are intentional in our approach
              to component API design.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Build for inclusivity.</Value>
            <Text>
              We strive to write perceivable, operable, and understandable
              patterns for all users. Accessibility should always be built in,
              rather than bolted on.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Progress over perfection.</Value>
            <Text>
              We favor practicality over purity, and take steps that keep the
              project moving incrementally forward.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Operate transparently.</Value>
            <Text>
              We aim to do our work out in the open. We’re not the only ones who
              know the status of the system, and we encourage contribution.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Prioritize a public API with a minimal surface area.</Value>
            <Text>
              We aim to balance API complexity with composability, and we favor
              an approach that avoids hasty abstractions.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Incremental correctness over time.</Value>
            <Text>
              We favor making decisions that move us forward incrementally based
              on long-term goals. We aim to identify the small and meaningful
              steps we can take that help make the best system we can, that
              helps people do their work and helps our users succeed.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Document everything, concisely.</Value>
            <Text>
              We favor documenting our system and processes as a way to
              encourage experimentation and learning and to set our future
              selves up for success. By documenting early and often, we allow
              ourselves to be guided by empathy for the people who come after us
              and for our future selves.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Value quality over quantity.</Value>
            <Text>
              The work we do won’t succeed if the quality is poor or below our
              standards.
            </Text>
          </ValueCard>
          <ValueCard>
            <Value>Operate with intention.</Value>
            <Text>
              We aim to define the problem and goal before jumping to solutions
              and implementations.
            </Text>
          </ValueCard>
        </ValuesList>
      </Stack>
    </ArticleLayout>
  )
}

const StyledHeading = styled(Heading)`
  font-size: ${themeGet("fontSizes.5")};
  margin-bottom: ${themeGet("space.2")};
`

const ValuesList = styled.ul`
  padding: 0;
  list-style: none;
  display: grid;
  gap: ${themeGet("space.2")};
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
`
const ValueCard = styled.li`
  border: 1px solid ${themeGet("colors.border.default")};
  border-radius: ${themeGet("radii.1")};
  padding: ${themeGet("space.4")};
  display: flex;
  flex-direction: column;
  gap: ${themeGet("space.2")};
`

const Value = styled(Text)`
  font-weight: ${themeGet("fontWeights.bold")};
`
export default About
